import React, { Component } from 'react'

export default class About extends Component {
  render() {
    return (
      <div>
        <section className="colorlib-about" data-section="about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-12">
                <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
                <div className="col-md-12">
                    <div className="about-desc">
                    {/* <span className="heading-meta"></span> */}
                    <h2 className="colorlib-heading">About Me</h2>
                    {<p>I am an Electronics and Communication Under-graduate from RV College of Engineering, Bangalore. Currently, I work at Texas Instruments as a Design Engineer with the Standard Cell Library Team working closely with Spice-Modelling and Process teams. </p>}
                    {<p>I am an avid supporter of sustainable technologies and I frequently go trekking to admire the beauty of nature. I am writing this blog to capture my experiences in the projects I have been involved in and the adventures I have gone on. </p>}
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        </section>
        <section className="colorlib-about">
        <div className="colorlib-narrow-content">
            <div className="row">
            <div className="col-md-6 col-md-offset-3 col-md-pull-3 animate-box" data-animate-effect="fadeInLeft">
                {/* <span className="heading-meta">What am i good at?</span> */}
                <h2 className="colorlib-heading">skills</h2>
            </div>
            </div>
            <div className="row row-pt-md">
            <div className="col-md-4 text-center animate-box">
                <div className="services color-1">
                <span className="icon">
                    <i className="icon-bulb" />
                </span>
                <div className="desc">
                    <h3>Programming </h3>
                    <p>Python, Verilog-HDL, C/C++, Lisp, Java, JavaScript, VBA</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-3">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Frameworks </h3>
                    <p> Pandas, SQLAlchemy, PyVerilog, AngularJS, Flask
                    </p>
                </div>
                </div>
            </div>
            
            
            <div className="col-md-4 text-center animate-box">
                <div className="services color-2">
                <span className="icon">
                    <i className="icon-data" />
                </span>
                <div className="desc">
                    <h3>Tools</h3>
                    <p>Cadence Virtuoso, Liberate, MS Excel, Octave, DesignSync</p>
                </div>
                </div>
            </div>
            {/*
            <div className="col-md-4 text-center animate-box">
                <div className="services color-4">
                <span className="icon">
                    <i className="icon-layers2" />
                </span>
                <div className="desc">
                    <h3>Graphic Design</h3>
                    <p>My friend knows .. P</p>
                </div>
                </div>
            </div>
            <div className="col-md-4 text-center animate-box">
                <div className="services color-6">
                <span className="icon">
                    <i className="icon-phone3" />
                </span>
                <div className="desc">
                    <h3>Digital Marketing</h3>
                    <p>I use Instagram eight hours a day :) </p>
                </div>
                </div>
            </div>
            */}
            </div>
        </div>
        </section>
      </div>
    )
  }
}
